@media (min-width: 500px) {
    .clock h1 {
        font-size: 4em;
    }
    
    .clock h2 {
        font-size: 2em;
    }

    .button-container button {
        padding: 1rem;
    }
}

@media (max-width: 499px) {
    .clock h1 {
        font-size: 3em;
    }

    .recaptcha-container {
        transform:scale(0.77);
        transform-origin:0 0;
    }
}